export const getStateMessages = (translate) => ({
  attach: translate('Attaching'),
  create_snapshot_schedule: translate('Creating snapshot schedule'),
  update: translate('Updating'),
  snapshot: translate('Taking a snapshot'),
  pull: translate('Pulling'),
  import_resource: translate('Importing resources'),
  extend: translate('Extending'),
  detach: translate('Detaching'),
  destroy: translate('Destroying'),
  restore: translate('Restroing'),
  change_flavor: translate('Changing flavor'),
  start: translate('Starting'),
  stop: translate('Stopping'),
  restart: translate('Restarting'),
  update_security_groups: translate('Updating security groups'),
  backup: translate('Backing up'),
  create_backup_schedule: translate('Creating a backup schedule'),
  update_ports: translate('Updating internal IPs'),
  update_floating_ips: translate('Updating floating IPs'),
  activate: translate('Activating'),
  deactivate: translate('Deactivating'),
  set_rules: translate('Updating rules'),
  set_quotas: translate('Updating quotas'),
  create_network: translate('Creating a new network'),
  create_subnet: translate('Creating a subnet'),
  create_floating_ip: translate('Creating a floating IP'),
  create_security_group: translate('Creating a security group'),
  change_password: translate('Changing password'),
  pull_quotas: translate('Pulling quotas'),
});
