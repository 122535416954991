export const ADD_PAYMENT_PROFILE =
  'waldur/payment-profiles/ADD_PAYMENT_PROFILE';
export const EDIT_PAYMENT_PROFILE =
  'waldur/payment-profiles/EDIT_PAYMENT_PROFILE';
export const REMOVE_PAYMENT_PROFILE =
  'waldur/payment-profiles/REMOVE_PAYMENT_PROFILE';
export const ENABLE_PAYMENT_PROFILE =
  'waldur/payment-profiles/ENABLE_PAYMENT_PROFILE';

export const ADD_PAYMENT_PROFILE_FORM_ID = 'AddPaymentProfile';
export const EDIT_PAYMENT_PROFILE_FORM_ID = 'EditPaymentProfile';
